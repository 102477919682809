import React, { useState, useEffect } from 'react';
import './Disbursement.css';

const Disbursement = () => {
  const [disbursements, setDisbursements] = useState([]);
  const [error, setError] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [loanId, setLoanId] = useState('');
  const [transactionAmount, setTransactionAmount] = useState('');
  const [userId, setUserId] = useState('');
  const [mode, setMode] = useState('cheque');
  const [filterLoanId, setFilterLoanId] = useState('');
  const [filterUserId, setFilterUserId] = useState('');
  const [disbursementTo, setdisbursementTo] = useState('');
  const [agentName, setagentName] = useState('');

  const branchId = localStorage.getItem('srishti_branch_id');

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        let url = 'https://srishticooperativesociety.com/api/transactions';
        if (filterLoanId || filterUserId) {
          url += `?loan_id=${filterLoanId}&user_id=${filterUserId}`;
        }

        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Failed to fetch transactions');
        }
        const data = await response.json();
        const disbursementData = data.filter(
          transaction => transaction.transaction_type === 'disbursement' && transaction.branch_id == branchId
        );
        setDisbursements(disbursementData);
        calculateTotalAmount(disbursementData);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchTransactions();
  }, [branchId, filterLoanId, filterUserId]);

  const calculateTotalAmount = (data) => {
    const total = data.reduce((acc, transaction) => acc + parseFloat(transaction.transaction_amount), 0);
    setTotalAmount(total);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  const handleAddDisbursement = async (e) => {
    e.preventDefault();
    const newDisbursement = {
      loan_id: loanId,
      transaction_type: 'disbursement',
      transaction_amount: transactionAmount,
      user_id: userId,
      branch_id: branchId,
      mode,
      disbursement_to: disbursementTo,
      agent_name: agentName,
    };

    try {
      const response = await fetch('https://srishticooperativesociety.com/api/transactions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newDisbursement),
      });

      if (!response.ok) {
        throw new Error('Failed to add disbursement');
      }

      // Refresh the entire page after disbursement is successfully posted
      window.location.reload();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="disbursement-page">
      <h2>Disbursement History</h2>
      {error && <p className="error-message">{error}</p>}
      <h3>Total Disbursement Amount: ₹{totalAmount.toFixed(2)}</h3>
      
      <form onSubmit={handleAddDisbursement} className="disbursement-form">
        <h3>Add Disbursement</h3>
        <div>
          <label>Loan ID:</label>
          <input
            type="number"
            value={loanId}
            onChange={(e) => setLoanId(e.target.value)}
            required
          />
        </div>

        
        <div>
          <label>Transaction Amount:</label>
          <input
            type="number"
            value={transactionAmount}
            onChange={(e) => setTransactionAmount(e.target.value)}
            required
          />
        </div>


        <div>
          <label>Disbursement To(Customer Name):</label>
          <input
            type="text"
            value={disbursementTo}
            onChange={(e) => setdisbursementTo(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Agent Name:</label>
          <input
            type="text"
            value={agentName}
            onChange={(e) => setagentName(e.target.value)}
            required
          />
        </div>

        <div>
          <label>Check Number(Details):</label>
          <input
            type="text"
          />
        </div>


        {/* <div>
          <label>Agent ID:</label>
          <input
            type="number"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            required
          />
        </div> */}
        <div>
          <label>Mode:</label>
          <select value={mode} onChange={(e) => setMode(e.target.value)} required>
            <option value="cheque">Cheque</option>
            <option value="cash">Cash</option>
            <option value="online">Online</option>
          </select>
        </div>
        <button type="submit">Add Disbursement</button>
      </form>

      <div className="filters">
        <div>
          <label>Filter by Loan ID:</label>
          <input
            type="text"
            value={filterLoanId}
            onChange={(e) => setFilterLoanId(e.target.value)}
          />
        </div>
        <div>
          <label>Filter by Agent Name:</label>
          <input
            type="text"
            value={filterUserId}
            onChange={(e) => setFilterUserId(e.target.value)}
          />
        </div>
      </div>

      <table className="transaction-table">
        <thead>
          <tr>
            <th>Loan ID</th>
            <th>Type</th>
            <th>Amount</th>
           
            <th>Date</th>
          
            <th>Mode</th>
            <th>Disbursement To</th>
            <th>Agent Name</th>
          </tr>
        </thead>
        <tbody>
          {disbursements.map(transaction => (
            <tr key={transaction.id}>
              <td>{transaction.loan_id}</td>
              <td>{transaction.transaction_type}</td>
              <td>₹{transaction.transaction_amount}</td>
             
              <td>{formatDate(transaction.created_at).toLocaleString()}</td>
             
              <td>{transaction.mode}</td>
              <td>{transaction.disbursement_to}</td>
              <td>{transaction.agent_name}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Disbursement;
