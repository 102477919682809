import React, { useState, useEffect } from 'react';
import './Collections.css';

const Collection = () => {
  const [collections, setCollections] = useState([]);
  const [filteredCollections, setFilteredCollections] = useState([]);
  const [collectorId, setCollectorId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [collectedFrom, setCollectedFrom] = useState('');
  const [collectedBy, setCollectedBy] = useState('');
  const [error, setError] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [collectorNames, setCollectorNames] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const branchId = localStorage.getItem('srishti_branch_id');
      if (!branchId) {
        setError('No branch ID found in local storage');
        return;
      }

      try {
        const response = await fetch('https://srishticooperativesociety.com/api/collections');
        if (!response.ok) {
          throw new Error('Failed to fetch collections');
        }
        const data = await response.json();
        const filteredCollections = data.filter(collection => collection.branch_id === parseInt(branchId, 10));
        setCollections(filteredCollections);
        setFilteredCollections(filteredCollections);
        calculateTotalAmount(filteredCollections);

        const uniqueCollectorIds = [...new Set(filteredCollections.map(col => col.collector_id))];
        const namesMap = {};
        for (let id of uniqueCollectorIds) {
          const nameResponse = await fetch(`https://srishticooperativesociety.com/api/admins/${id}`);
          if (nameResponse.ok) {
            const nameData = await nameResponse.json();
            namesMap[id] = nameData.name; // assuming 'name' is the field in the response
          }
        }
        setCollectorNames(namesMap);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchData();
  }, []);

  const calculateTotalAmount = (data) => {
    const total = data
      .filter(collection => collection.status === 'accepted')
      .reduce((acc, collection) => acc + parseFloat(collection.collection_amount), 0);
    setTotalAmount(total);
  };

  const updateCollectionStatus = async (id, status) => {
    const collection = collections.find(c => c.id === id);
    
    // Check if the collection has already been accepted
    if (status === 'rejected' && collection.status === 'accepted') {
      setError('This collection has already been accepted and cannot be rejected. Please contact the admin if you think you made a mistake.');
      return;
    }

    const url = `https://srishticooperativesociety.com/api/collections/${id}`;
    try {
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      });
  
      if (!response.ok) {
        throw new Error(`Failed to ${status} collection`);
      }
  
      const updatedCollections = collections.map(collection =>
        collection.id === id ? { ...collection, status } : collection
      );
      setCollections(updatedCollections);
      filterCollections(updatedCollections);
    } catch (error) {
      setError(error.message);
    }
  };

  const filterCollections = (collectionsToFilter = collections) => {
    let filtered = collectionsToFilter;
    
    if (collectorId !== '') {
      filtered = filtered.filter(collection => collection.collector_id === parseInt(collectorId, 10));
    }

    if (collectedFrom) {
      filtered = filtered.filter(collection => 
        collection.collected_from.toLowerCase().includes(collectedFrom.toLowerCase())
      );
    }

    if (collectedBy) {
      filtered = filtered.filter(collection => 
        collectorNames[collection.collector_id]?.toLowerCase().includes(collectedBy.toLowerCase())
      );
    }

    if (selectedDate) {
      filtered = filtered.filter(collection => 
        new Date(collection.collection_date).toDateString() === new Date(selectedDate).toDateString()
      );
    }

    if (startDate && endDate) {
      filtered = filtered.filter(collection => {
        const collectionDate = new Date(collection.collection_date);
        return collectionDate >= new Date(startDate) && collectionDate <= new Date(endDate);
      });
    }

    setFilteredCollections(filtered);
    calculateTotalAmount(filtered);
  };

  const handleCollectorIdChange = (e) => {
    const value = e.target.value;
    setCollectorId(value);
    filterCollections();
  };

  const handleCollectedFromChange = (e) => {
    const value = e.target.value;
    setCollectedFrom(value);
    filterCollections();
  };

  const handleCollectedByChange = (e) => {
    const value = e.target.value;
    setCollectedBy(value);
    filterCollections();
  };

  const handleStartDateChange = (e) => {
    const value = e.target.value;
    setStartDate(value);
    filterCollections();
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;
    setEndDate(value);
    filterCollections();
  };

  const handleDateChange = (e) => {
    const value = e.target.value;
    setSelectedDate(value);
    filterCollections();
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  return (
    <div className="collection-container">
      <h1>Collections</h1>
      {error && <p className="error-message">{error}</p>}
      <h3>Total Collections Amount (Accepted by Branch): ₹{totalAmount.toFixed(2)}</h3>
      
      <input
        type="text"
        className='filter'
        placeholder="Filter by Collected From"
        value={collectedFrom}
        onChange={handleCollectedFromChange}
      />
      <input
        type="text"
        className='filter'
        placeholder="Filter by Collected By"
        value={collectedBy}
        onChange={handleCollectedByChange}
      />
      <input
        type="number"
        className='filter'
        placeholder="Filter by FieldOfficer Name/ID"
        value={collectorId}
        onChange={handleCollectorIdChange}
      />
      <label>Start Date</label>
      <input
        type="date"
        className='filter'
        placeholder="Start Date"
        value={startDate}
        onChange={handleStartDateChange}
      />
      <label>End Date</label>
      <input
        type="date"
        className='filter'
        placeholder="End Date"
        value={endDate}
        onChange={handleEndDateChange}
      />
      <label>Select Date</label>
      <input
        type="date"
        className='filter'
        placeholder="Select Date"
        value={selectedDate}
        onChange={handleDateChange}
      />
      <table className="collections-table">
        <thead>
          <tr>
            <th>Loan ID</th>
            <th>Collected From</th>
            <th>Collected By</th>
            <th>Collection Amount</th>
            <th>Collection Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredCollections.map(collection => (
            <tr key={collection.id}>
              <td>{collection.loan_id}</td>
              <td>{collection.collected_from}</td>
              <td>{collectorNames[collection.collector_id] || 'Loading...'}</td>
              <td>₹{collection.collection_amount}</td>
              <td>{formatDate(collection.collection_date)}</td>
              <td>{collection.status}</td>
              <td>
                <button className='accept-button' onClick={() => updateCollectionStatus(collection.id, 'accepted')}>Accept</button>
                <button className='reject-button' onClick={() => updateCollectionStatus(collection.id, 'rejected')}>Reject</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <p className="contact-admin">*Once a collection is accepted, it cannot be rejected. Please contact the admin if you think you made a mistake.</p>
    </div>
  );
};

export default Collection;
