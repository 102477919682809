import React, { useState, useEffect } from 'react';
import './LoanRequests.css';

const LoanRequests = () => {
  const [loanRequests, setLoanRequests] = useState([]);
  const [filteredLoanRequests, setFilteredLoanRequests] = useState([]);
  const [error, setError] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [agentFilter, setAgentFilter] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentLoanRequest, setCurrentLoanRequest] = useState(null);
  const [newCollector, setNewCollector] = useState('');
  const [newInterestRate, setNewInterestRate] = useState('');

  const [collections, setCollections] = useState([]);
  const [totalCollected, setTotalCollected] = useState(0);
  const [showCollections, setShowCollections] = useState(false);

  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [imageType, setImageType] = useState('');

  useEffect(() => {
    fetchLoanRequests();
  }, []);

  const fetchUserDetails = async (userId) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/admins/${userId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch user details for ID ${userId}`);
      }
      const userData = await response.json();
      return { agentFirstname: userData.name };
    } catch (error) {
      console.error(error);
      return { agentFirstname: 'Unknown' };
    }
  };

  const fetchLoanRequests = async () => {
    const branchId = localStorage.getItem('srishti_branch_id');
    if (!branchId) {
      setError('No branch ID found in local storage');
      return;
    }

    try {
      const response = await fetch('https://srishticooperativesociety.com/api/loan_requests');
      if (!response.ok) {
        throw new Error('Failed to fetch loan requests');
      }
      const data = await response.json();
      const filteredData = data.filter(request => request.branch_id === parseInt(branchId, 10));

      const updatedData = await Promise.all(filteredData.map(async request => {
        const agentDetails = await fetchUserDetails(request.collector_assigned);
        return { 
          ...request, 
          agentFirstname: agentDetails.agentFirstname
        };
      }));

      setLoanRequests(updatedData);
      setFilteredLoanRequests(updatedData);
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchCollections = async (loanId) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/collections/loan/${loanId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch collections for loan ID ${loanId}`);
      }
      const data = await response.json();

      const totalCollected = data
        .filter(collection => collection.status === 'accepted')
        .reduce((sum, collection) => sum + parseFloat(collection.collection_amount), 0);

      setCollections(data);
      setTotalCollected(totalCollected);
      setShowCollections(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const fetchImage = async (imageType, requestId) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${requestId}`);
      if (!response.ok) {
        throw new Error(`Failed to fetch ${imageType}`);
      }
      const data = await response.json();
      const imageUrl = imageType === 'Aadhar' ? data.aadhar_card : data.profile_picture;
      setCurrentImage(`https://srishticooperativesociety.com/storage/app/` + imageUrl);
      setImageType(imageType);
      setIsImageModalOpen(true);
    } catch (error) {
      setError(error.message);
    }
  };

  const updateApprovalStatus = async (id, status) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ approval_status: status }),
      });

      if (!response.ok) {
        throw new Error(`Failed to ${status} loan request`);
      }

      const updatedLoanRequests = loanRequests.map(request =>
        request.id === id ? { ...request, approval_status: status } : request
      );
      setLoanRequests(updatedLoanRequests);
      setFilteredLoanRequests(updatedLoanRequests);
    } catch (error) {
      setError(error.message);
    }
  };

  const updateLoanRequest = async (id, interestRate, collectorAssigned) => {
    try {
      const response = await fetch(`https://srishticooperativesociety.com/api/loan_requests/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ interest_rate: interestRate, collector_assigned: collectorAssigned }),
      });

      if (!response.ok) {
        throw new Error('Failed to update loan request');
      }

      const updatedLoanRequests = loanRequests.map(request =>
        request.id === id ? { ...request, interest_rate: interestRate, collector_assigned: collectorAssigned } : request
      );
      setLoanRequests(updatedLoanRequests);
      setFilteredLoanRequests(updatedLoanRequests);
      setIsModalOpen(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    filterLoanRequests(query, agentFilter);
  };

  const handleAgentFilter = (e) => {
    const agentName = e.target.value.toLowerCase();
    setAgentFilter(agentName);
    filterLoanRequests(searchQuery, agentName);
  };

  const filterLoanRequests = (query, agentName) => {
    const filtered = loanRequests.filter(request =>
      (request.id.toString().includes(query) ||
       request.firstname.toLowerCase().includes(query) ||
       request.lastname.toLowerCase().includes(query)) &&
      (request.agentFirstname.toLowerCase().includes(agentName) || agentName === '')
    );
    setFilteredLoanRequests(filtered);
  };

  const openModal = (loanRequest) => {
    setCurrentLoanRequest(loanRequest);
    setNewCollector(loanRequest.collector_assigned || '');
    setNewInterestRate(loanRequest.interest_rate.toString());
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentLoanRequest(null);
    setNewCollector('');
    setNewInterestRate('');
  };

  const handleCollectorChange = (e) => {
    setNewCollector(e.target.value);
  };

  const handleInterestRateChange = (e) => {
    setNewInterestRate(e.target.value);
  };

  const handleSaveChanges = () => {
    if (currentLoanRequest) {
      updateLoanRequest(currentLoanRequest.id, parseFloat(newInterestRate), newCollector);
    }
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setCurrentImage(null);
    setImageType('');
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-GB', options).format(date);
  };

  return (
    <div className="loan-requests-page">
      <h2>Loan Requests</h2>
      {error && <p className="error-message">{error}</p>}
      <input
        type="text"
        placeholder="Search by ID, Firstname, or Lastname"
        value={searchQuery}
        onChange={handleSearch}
        className="search-input"
      />
      <input
        type="text"
        placeholder="Filter by Agent Name"
        value={agentFilter}
        onChange={handleAgentFilter}
        className="agent-filter-input"
      />
      <table className="loan-requests-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Type</th>
            <th>Customer Name</th>
            <th>Address</th>
            <th>Interest Rate</th>
            <th>Duration</th>
            <th>Installment Type</th>
            <th>Amount</th>
            <th>View Aadhar</th>
            <th>View Photo</th>
            <th>Agent Name</th>
            <th>Status</th>
            <th>Amount (incl. Interest)</th>
            <th>Submitted at</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredLoanRequests.map(request => (
            <tr key={request.id}>
              <td>{request.id}</td>
              <td>{request.loan_type}</td>
              <td>{request.firstname} {request.lastname}</td>
              <td>{request.address}</td>
              <td>{request.interest_rate}%</td>
              <td>{request.duration} months</td>
              <td>{request.installment_type}</td>
              <td>₹{request.amount}</td>
              <td>
                <button onClick={() => fetchImage('Aadhar', request.id)}>View Aadhar</button>
              </td>
              <td>
                <button onClick={() => fetchImage('Photo', request.id)}>View Photo</button>
              </td>
              <td>{request.agentFirstname}</td>
              <td>{request.approval_status}</td>
              <td>₹{((Number(request.amount)*(Number(request.interest_rate)*(Number(request.duration))/100)))+Number(request.amount)}</td>
              <td>{formatDate(request.created_at)}</td>
              <td>
                <button onClick={() => openModal(request)}>Edit</button>
                <button onClick={() => updateApprovalStatus(request.id, 'approved')}>Approve</button>
                <button onClick={() => updateApprovalStatus(request.id, 'rejected')}>Reject</button>
                <button onClick={() => fetchCollections(request.id)}>View Collections</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && currentLoanRequest && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <h3>Edit Loan Request</h3>
            <div className="form-group">
              <label>Collector:</label>
              <input
                type="text"
                value={newCollector}
                onChange={handleCollectorChange}
              />
            </div>
            <div className="form-group">
              <label>Interest Rate:</label>
              <input
                type="number"
                value={newInterestRate}
                onChange={handleInterestRateChange}
              />
            </div>
            <button onClick={handleSaveChanges}>Save Changes</button>
          </div>
        </div>
      )}

      {isImageModalOpen && currentImage && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeImageModal}>&times;</span>
            <h3>{imageType} Image</h3>
            <img src={currentImage} alt={imageType} />
          </div>
        </div>
      )}

      {showCollections && collections.length > 0 && (
        <div className="collections-section">
          <h3>Collections for Loan ID {currentLoanRequest?.id}</h3>
          <table className="collections-table">
            <thead>
              <tr>
              
                <th>Amount</th>
                <th>Status</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {collections.map(collection => (
                <tr key={collection.id}>
                
                  <td>{collection.collection_amount}</td>
                  <td>{collection.status}</td>
                  <td>{formatDate(collection.collection_date)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p>Total Collected: {totalCollected}</p>
        </div>
      )}
    </div>
  );
};

export default LoanRequests;
